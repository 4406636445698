<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 32 32">
        <path
            stroke="#074A74"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="3"
            d="M25 28H6.999a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1H19l7 7v16a1.001 1.001 0 0 1-1 1Z"
        />
        <path stroke="#074A74" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M18.5 4v7.5h7.501M12 16.5h8M12 21.5h8" />
    </svg>
</template>
