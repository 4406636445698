<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" width="24" height="24" rx="12" fill="#074A74" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.5 7.5C12.9971 7.5 13.4 7.90294 13.4 8.4V11.1L16.1 11.1C16.5971 11.1 17 11.5029 17 12C17 12.4971 16.5971 12.9 16.1 12.9H13.4V15.6C13.4 16.0971 12.9971 16.5 12.5 16.5C12.0029 16.5 11.6 16.0971 11.6 15.6V12.9H8.9C8.40294 12.9 8 12.4971 8 12C8 11.5029 8.40294 11.1 8.9 11.1L11.6 11.1V8.4C11.6 7.90294 12.0029 7.5 12.5 7.5Z"
      fill="white"
    />
  </svg>
</template>
